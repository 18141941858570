import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';
import { DataService } from 'src/app/shared/services/data.service';
import { GoogleAuthService } from 'src/app/auth/services/google-auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  userCredential = {
    name: '',
    image: ''
  };



  logOut() {
    this.gService.signOut();
    // this.router.navigate(['/']);
    window.location.href = '/';
  }
  constructor(private route: Router, private dataService: DataService, private apiService: ApiService, private gService: GoogleAuthService) { }
  mobileSideNav: boolean;
  showCloseIcon: boolean;
  showProfile: boolean;
  ngOnInit() {
    const obj = this.gService.getAccessToken();
    this.userCredential.name = obj['name'];
    this.userCredential.image = obj['image'];
  }
  showMobileSideNav() {
    this.mobileSideNav = true;
    this.showCloseIcon = true;
  }
  hideMobileSideNav() {
    this.mobileSideNav = false;
    this.showCloseIcon = false;
  }
  gotoDashboard() {
    this.route.navigate(['landing']);
  }
  gotoListPage(e) {
    if (e.currentTarget.innerText == "Dashboard") {
      this.dataService.clickCompaigns = false;
      this.dataService.clickAdGroups = false;
      this.dataService.clickAds = false;
      this.route.navigate(['redditAds/dashboard']);
    }
    if (e.currentTarget.innerText == "Campaigns") {
      this.dataService.listData = this.apiService.getCompaignsData();
      this.dataService.clickCompaigns = true;
      this.dataService.clickAdGroups = false;
      this.dataService.clickAds = false;
      this.dataService.viewListTitle = "Campaigns";
      this.route.navigate(['redditAds/campaigns']);
    }
    if (e.currentTarget.innerText == "Ad Group") {
      this.dataService.listData = this.apiService.getAdGroupsData();
      this.dataService.clickCompaigns = false;
      this.dataService.clickAdGroups = true;
      this.dataService.clickAds = false;
      this.dataService.viewListTitle = "Ad Groups";
      this.route.navigate(['redditAds/adGroup']);
    }
    if (e.currentTarget.innerText == "Ads") {
      this.dataService.viewListTitle = "Ads";
      this.dataService.listData = this.apiService.getAdsData();
      this.dataService.clickCompaigns = false;
      this.dataService.clickAdGroups = false;
      this.dataService.clickAds = true;
      this.route.navigate(['redditAds/ads']);
    }
    this.mobileSideNav = false;
    this.showCloseIcon = false;
  }
  showProfileData() {
    this.showProfile = !this.showProfile;
  }
  hideProfile() {
    this.showProfile = false;
  }
  logout() {
    this.gService.signOut();
    window.location.href='login';
  }

}
