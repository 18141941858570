import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  constructor(public route:Router,public apiService:ApiService,public dataService:DataService) {
    this.dataService.accountID=localStorage.getItem('accountID');
   }
  responseData:any=[];
  ngOnInit() {
    
  }
  gotoListPage(e){
    if(e.currentTarget.innerText=="Dashboard"){
      this.dataService.clickCompaigns=false;
          this.dataService.clickAdGroups=false;
          this.dataService.clickAds=false;
      this.route.navigate(['redditAds/dashboard']);
      // const obj = {
      //   account_id : this.dataService.accountID,
      //   end_date: this.dataService.endDate,
      //   start_date: this.dataService.startDate
      // }
      // console.log(obj);
      // this.dataService.showLoader=true;
      // this.dataService.getData(obj).subscribe(
      //   (res) => {
      //     if(res["data"]){
      //       if(res["data"].length!=0){
      //         console.log(res);
      //         console.log("response"+res["data"])
      //         let top5List=res["data"];
      //         this.apiService.top5CompaignsList=top5List[0].campaigns;
      //         this.apiService.top5AdGroupsList=top5List[1].ad_groups;
      //         this.apiService.top5AdsList=top5List[2].ads;
      //         //this.route.navigate(['redditAds']);
      //         this.apiService.compaignsList=this.apiService.top5CompaignsList;
      //         this.apiService.adGroupsList=this.apiService.top5AdGroupsList;
      //         this.apiService.adsList=this.apiService.top5AdsList;
      //         this.apiService.compaignsList=this.dataService.top5spendConversion(this.apiService.compaignsList);
              
      //         this.apiService.adGroupsList=this.dataService.top5spendConversion(this.apiService.adGroupsList)
      //         this.apiService.adsList=this.dataService.top5spendConversion(this.apiService.adsList);
      //         this.dataService.compaignGraphList=JSON.parse(JSON.stringify(this.apiService.compaignsList));
      //         this.dataService.adGroupGraphList=JSON.parse(JSON.stringify(this.apiService.adGroupsList));
      //         this.dataService.adsGraphList=JSON.parse(JSON.stringify(this.apiService.adsList));
              
      //           this.dataService.renameCompaignsData();
      //           this.dataService.renameAdGroupsData();
      //           this.dataService.renameAdsData();
      //         this.dataService.showLoader=false;
      //         this.dataService.hideElement=false;
      //         this.dataService.errorMsg='';
      //         this.dataService.showErrorMsg=false;
      //       }
      //       if(res['data'].length==0){
      //         this.responseData=res['data'];
      //         this.dataService.showLoader=false;
      //         this.dataService.showErrorMsg=true;
      //         this.dataService.errorMsg="No Data Available. Please check with other Account Number";
      //         this.dataService.hideElement=true;
      //       }
      //       //this.dataService.hideElement=false;
          
      //     }else{
      //       this.responseData.length=1;
      //       this.dataService.showLoader=false;
      //       this.dataService.errorMsg='';
      //       this.dataService.hideElement=true;
      //       this.dataService.showErrorMsg=true;
      //       this.dataService.errorMsg=res['error'].message;
            
      //     }
      //   },
      //   (error) => {
      //     console.log(error)
      //     this.dataService.showLoader=false;
      //     this.route.navigate(['/error', { id: error.status}]);
      //    // this.route.navigate(['landing']);
      //   }
      // )
      
      
    }
    if(e.currentTarget.innerText=="Campaigns"){
      
      this.dataService.clickCompaigns=true;
          this.dataService.clickAdGroups=false;
          this.dataService.clickAds=false;
          this.dataService.clickFilterCampaign=true;
          this.dataService.clickFilterAdgroup=false;
          this.dataService.clickFilterAd=false;
      this.route.navigate(['redditAds/campaigns']);
      // this.dataService.showLoader=true;
      

      // const obj = {
      //   account_id : this.dataService.accountID,
      //   end_date: this.dataService.endDate,
      //   start_date: this.dataService.startDate
      // }
      // this.dataService.getCampaignsList(obj).subscribe(
      //   (res) => {
      //     if(res["data"]){
      //       if(res["data"].length!=0){
      //         let campaignsList1=res["data"];
      //         this.apiService.compaignsList=campaignsList1[0].campaigns;
      //         console.log("this.apiService.compaignsList"+this.apiService.compaignsList);
      //         this.dataService.listData=this.apiService.compaignsList;
              

      //         this.dataService.viewListTitle="Campaigns";
      //         this.dataService.allItems=this.dataService.listData;
      //         //this.dataService.filterCampaignsList=JSON.parse(JSON.stringify(this.dataService.allItems));
      //         this.dataService.dummyAllItems=JSON.parse(JSON.stringify(this.dataService.allItems));
      //         console.log("this.dataService.allItems.length"+this.dataService.allItems.length)
      //         this.dataService.allItems=this.dataService.spendConversion(this.dataService.allItems);
      //         this.dataService.pageSize=10;
      //           this.dataService.setPage(1);
      //           this.dataService.getTotal();
      //           this.dataService.showLoader=false;
      //           this.dataService.hideElement=false;
      //           this.dataService.errorMsg='';
      //           this.dataService.showErrorMsg=false;
      //         }
      //         if(res['data'].length==0){
      //           this.responseData=res['data'];
      //           this.dataService.showLoader=false;
      //           this.dataService.showErrorMsg=true;
      //           this.dataService.errorMsg="No Data Available. Please check with other Account Number";
      //           this.dataService.hideElement=true;
      //         }
      //       //this.dataService.hideElement=false;
          
      //     }else{
      //       this.responseData.length=1;
      //       this.dataService.showLoader=false;
      //       this.dataService.errorMsg='';
      //       this.dataService.hideElement=true;
      //       this.dataService.showErrorMsg=true;
      //       this.dataService.errorMsg=res['error'].message;
      //     }
      //   },
      //   (error)=>{
      //     this.dataService.showLoader=false;
      //     console.log(error)
      //     this.route.navigate(['/error', { id: error.status}]);
      //   }
      // )

      const filterObj={
        account_id : this.dataService.accountID
      }
      this.dataService.getAllFilterList(filterObj).subscribe(
          (res) => {
            // console.log(res);
            // console.log("response"+res["data"])
            
            if(res["data"]){
              if(res['data'].length!=0){
                let filterList=res["data"][0];
              // console.log("filterList from landing page"+filterList);
              this.dataService.filterCampaignsList=filterList.campaigns;
              this.dataService.filterAdGroupsList=[];
              this.dataService.filterAdsList=[];
              for(var i=0;i<this.dataService.filterCampaignsList.length;i++){
                if(this.dataService.filterCampaignsList[i].ad_groups.length!=0){
                  for(var j=0;j<this.dataService.filterCampaignsList[i].ad_groups.length;j++){
                    this.dataService.filterAdGroupsList.push(this.dataService.filterCampaignsList[i].ad_groups[j])
                  }
                }
                
              }
              for(var k=0;k<this.dataService.filterAdGroupsList.length;k++){
                if(this.dataService.filterAdGroupsList[k].ads.length!=0){
                  for(var l=0;l<this.dataService.filterAdGroupsList[k].ads.length;l++){
                    this.dataService.filterAdsList.push(this.dataService.filterAdGroupsList[k].ads[l])
                  }
                }
               // this.dataService.filterAdsList.push(this.dataService.filterAdGroupsList[i].ads)
              }
              for(var i=0;i<this.dataService.filterCampaignsList.length;i++){
                this.dataService.filterCampaignsList[i]['isChecked']=false;
                
                
              }
              for(var i=0;i<this.dataService.filterAdGroupsList.length;i++){
                this.dataService.filterAdGroupsList[i]['isChecked']=false;
                
              }
              for(var i=0;i<this.dataService.filterAdsList.length;i++){
                this.dataService.filterAdsList[i]['isChecked']=false;
                
              }
              this.dataService.originalFilterCampaignsList=JSON.parse(JSON.stringify(this.dataService.filterCampaignsList));
              this.dataService.originalFilterAdGroupsList=JSON.parse(JSON.stringify(this.dataService.filterAdGroupsList));
              this.dataService.originalFilterAdsList=JSON.parse(JSON.stringify(this.dataService.filterAdsList));
              // console.log("this.dataService.filterAdGroupsList from landing:" +this.dataService.filterAdGroupsList);
              // console.log("this.dataService.filterAdsList from landing:" +this.dataService.filterAdsList);
              
              }
              if(res['data'].length==0){
                
              }
              
            }else{
              // console.log("errorMessage");
              
            }
            
          },
          (error) => {
            
          }
        )
      
    }
    if(e.currentTarget.innerText=="Ad Groups"){
      
      this.dataService.clickCompaigns=false;
        this.dataService.clickAdGroups=true;
        this.dataService.clickAds=false;
        this.dataService.clickFilterCampaign=true;
          this.dataService.clickFilterAdgroup=true;
          this.dataService.clickFilterAd=false;
      this.route.navigate(['redditAds/adGroup']);
    //   this.dataService.showLoader=true;
    //   const obj = {
    //     account_id : this.dataService.accountID,
    //     end_date: this.dataService.endDate,
    //     start_date: this.dataService.startDate
    //   }
    // this.dataService.getAdGroupsList(obj).subscribe(
    //   (res) => {
    //     if(res["data"]){
    //       if(res["data"].length!=0){
    //         let adGroupsList1=res["data"];
    //         this.apiService.adGroupsList=adGroupsList1[0].ad_groups;
    //         this.dataService.listData=this.apiService.adGroupsList;
            
    //         this.dataService.viewListTitle="Ad Groups";
    //         this.dataService.allItems=this.dataService.listData;
    //         this.dataService.allItems=this.dataService.spendConversion(this.dataService.allItems);
    //         //this.dataService.filterAdGroupsList=JSON.parse(JSON.stringify(this.dataService.allItems));
    //         this.dataService.pageSize=10;
    //           this.dataService.setPage(1);
    //           this.dataService.getTotal();
    //           this.dataService.showLoader=false;
    //         this.dataService.hideElement=false;
    //         this.dataService.errorMsg='';
    //         this.dataService.showErrorMsg=false;
    //       }
    //       if(res['data'].length==0){
    //         this.responseData=res['data'];
    //         this.dataService.showLoader=false;
    //         this.dataService.showErrorMsg=true;
    //         this.dataService.errorMsg="No Data Available. Please check with other Account Number";
    //         this.dataService.hideElement=true;
    //       }
    //     }else{
    //       this.responseData.length=1;
    //         this.dataService.showLoader=false;
    //         this.dataService.errorMsg='';
    //         this.dataService.hideElement=true;
    //         this.dataService.showErrorMsg=true;
    //         this.dataService.errorMsg=res['error'].message;
    //       }
    //       //this.dataService.hideElement=false;
        
        
    //   },
    //   (error)=>{
    //     this.dataService.showLoader=false;
    //     console.log(error)
    //     this.route.navigate(['/error', { id: error.status}]);
    //   }
    // )

    const filterObj={
      account_id : this.dataService.accountID
    }
    this.dataService.getAllFilterList(filterObj).subscribe(
        (res) => {
          // console.log(res);
          // console.log("response"+res["data"])
          
          if(res["data"]){
            if(res['data'].length!=0){
              let filterList=res["data"][0];
            // console.log("filterList from landing page"+filterList);
            this.dataService.filterCampaignsList=filterList.campaigns;
            this.dataService.filterAdGroupsList=[];
            this.dataService.filterAdsList=[];
            for(var i=0;i<this.dataService.filterCampaignsList.length;i++){
              if(this.dataService.filterCampaignsList[i].ad_groups.length!=0){
                for(var j=0;j<this.dataService.filterCampaignsList[i].ad_groups.length;j++){
                  this.dataService.filterAdGroupsList.push(this.dataService.filterCampaignsList[i].ad_groups[j])
                }
              }
              
            }
            for(var k=0;k<this.dataService.filterAdGroupsList.length;k++){
              if(this.dataService.filterAdGroupsList[k].ads.length!=0){
                for(var l=0;l<this.dataService.filterAdGroupsList[k].ads.length;l++){
                  this.dataService.filterAdsList.push(this.dataService.filterAdGroupsList[k].ads[l])
                }
              }
             // this.dataService.filterAdsList.push(this.dataService.filterAdGroupsList[i].ads)
            }
            for(var i=0;i<this.dataService.filterCampaignsList.length;i++){
              this.dataService.filterCampaignsList[i].isChecked=false;
              
              
            }
            for(var i=0;i<this.dataService.filterAdGroupsList.length;i++){
              this.dataService.filterAdGroupsList[i].isChecked=false;
              
            }
            for(var i=0;i<this.dataService.filterAdsList.length;i++){
              this.dataService.filterAdsList[i].isChecked=false;
              
            }
            this.dataService.originalFilterCampaignsList=JSON.parse(JSON.stringify(this.dataService.filterCampaignsList));
            this.dataService.originalFilterAdGroupsList=JSON.parse(JSON.stringify(this.dataService.filterAdGroupsList));
            this.dataService.originalFilterAdsList=JSON.parse(JSON.stringify(this.dataService.filterAdsList));
            // console.log("this.dataService.filterAdGroupsList from landing:" +this.dataService.filterAdGroupsList);
            // console.log("this.dataService.filterAdsList from landing:" +this.dataService.filterAdsList);
            
            }
            if(res['data'].length==0){
              
            }
            
          }else{
            console.log("errorMessage");
            
          }
          
        },
        (error) => {
          
        }
      )
      
    }
    if(e.currentTarget.innerText=="Ads"){
      
      this.dataService.clickCompaigns=false;
        this.dataService.clickAdGroups=false;
        this.dataService.clickAds=true;
        this.dataService.clickFilterCampaign=true;
          this.dataService.clickFilterAdgroup=true;
          this.dataService.clickFilterAd=true;
      this.route.navigate(['redditAds/ads']);
    //   this.dataService.showLoader=true;
    //   const obj = {
    //     account_id : this.dataService.accountID,
    //     end_date: this.dataService.endDate,
    //     start_date: this.dataService.startDate
    //   }
    // this.dataService.getAdsList(obj).subscribe(
    //   (res) => {
    //     if(res["data"]){
    //       if(res["data"].length!=0){
    //         let adsList1=res["data"];
    //         this.apiService.adsList=adsList1[0].ads;
    //         this.dataService.viewListTitle="Ads";
    //         this.dataService.listData=this.apiService.adsList;
            
    //         this.dataService.allItems=this.dataService.listData;
    //         this.dataService.allItems=this.dataService.spendConversion(this.dataService.allItems);
    //         //this.dataService.filterAdsList=JSON.parse(JSON.stringify(this.dataService.allItems));
    //         this.dataService.pageSize=10;
    //           this.dataService.setPage(1);
    //           this.dataService.getTotal();
    //           this.dataService.showLoader=false;
    //         this.dataService.hideElement=false;
    //         this.dataService.errorMsg='';
    //         this.dataService.showErrorMsg=false;
    //       }
    //       if(res['data'].length==0){
    //         this.responseData=res['data'];
    //         this.dataService.showLoader=false;
    //         this.dataService.showErrorMsg=true;
    //         this.dataService.errorMsg="No Data Available. Please check with other Account Number";
    //         this.dataService.hideElement=true;
    //       }
    //     }else{
    //       this.responseData.length=1;
    //         this.dataService.showLoader=false;
    //         this.dataService.errorMsg='';
    //         this.dataService.hideElement=true;
    //         this.dataService.showErrorMsg=true;
    //         this.dataService.errorMsg=res['error'].message;
    //       }
    //      // this.dataService.hideElement=false;
        
        
    //   },
    //   (error)=>{
    //     this.dataService.showLoader=false;
    //     console.log(error)
    //     this.route.navigate(['/error', { id: error.status}]);
    //   }
    // )
      
    const filterObj={
      account_id : this.dataService.accountID
    }
    this.dataService.getAllFilterList(filterObj).subscribe(
        (res) => {
          // console.log(res);
          // console.log("response"+res["data"])
          
          if(res["data"]){
            if(res['data'].length!=0){
              let filterList=res["data"][0];
            // console.log("filterList from landing page"+filterList);
            this.dataService.filterCampaignsList=filterList.campaigns;
            this.dataService.filterAdGroupsList=[];
            this.dataService.filterAdsList=[];
            for(var i=0;i<this.dataService.filterCampaignsList.length;i++){
              if(this.dataService.filterCampaignsList[i].ad_groups.length!=0){
                for(var j=0;j<this.dataService.filterCampaignsList[i].ad_groups.length;j++){
                  this.dataService.filterAdGroupsList.push(this.dataService.filterCampaignsList[i].ad_groups[j])
                }
              }
              
            }
            for(var k=0;k<this.dataService.filterAdGroupsList.length;k++){
              if(this.dataService.filterAdGroupsList[k].ads.length!=0){
                for(var l=0;l<this.dataService.filterAdGroupsList[k].ads.length;l++){
                  this.dataService.filterAdsList.push(this.dataService.filterAdGroupsList[k].ads[l])
                }
              }
             // this.dataService.filterAdsList.push(this.dataService.filterAdGroupsList[i].ads)
            }
            for(var i=0;i<this.dataService.filterCampaignsList.length;i++){
              this.dataService.filterCampaignsList[i]['isChecked']=false;
              
              
            }
            for(var i=0;i<this.dataService.filterAdGroupsList.length;i++){
              this.dataService.filterAdGroupsList[i]['isChecked']=false;
              
            }
            for(var i=0;i<this.dataService.filterAdsList.length;i++){
              this.dataService.filterAdsList[i]['isChecked']=false;
              
            }
            this.dataService.originalFilterCampaignsList=JSON.parse(JSON.stringify(this.dataService.filterCampaignsList));
            this.dataService.originalFilterAdGroupsList=JSON.parse(JSON.stringify(this.dataService.filterAdGroupsList));
            this.dataService.originalFilterAdsList=JSON.parse(JSON.stringify(this.dataService.filterAdsList));
            // console.log("this.dataService.filterAdGroupsList from landing:" +this.dataService.filterAdGroupsList);
            // console.log("this.dataService.filterAdsList from landing:" +this.dataService.filterAdsList);
            
            }
            if(res['data'].length==0){
              
            }
            
          }else{
            // console.log("errorMessage");
            
          }
          
        },
        (error) => {
          
        }
      )
    }
  }
}
