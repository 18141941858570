import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataService {
    api = environment.apiBaseUrl;
    clickCompaigns: boolean;
    clickAdGroups: boolean;
    clickAds: boolean;
    listData: any;
    viewListTitle: any;
    detailedPageTitle: any;
    clickDetailedCompaign: boolean;
    clickDetailedAdGroup: boolean;
    clickDetailedAd: boolean;
    accountID: string;
    startDate: any;
    endDate: any;
    showLoader: boolean;
    totalAmount: any = 0;
    totalImpressions: any = 0;
    totalClicks: any = 0;
    totalEcpm: any = 0;
    totalcpc: any = 0;
    totalGoal: any = 0;
    totalBid: any = 0;
    compaignGraphList = [];
    adGroupGraphList = [];
    adsGraphList = [];
    allItems: any[];
    filterCampaignsList: any = [];
    filterAdGroupsList: any = [];
    filterAdsList: any = [];
    originalFilterCampaignsList: any = [];
    originalFilterAdGroupsList: any = [];
    originalFilterAdsList: any = [];
    dummyAllItems: any = [];
    clickFilterCampaign: boolean;
    clickFilterAdgroup: boolean;
    clickFilterAd: boolean;
    // pager object
    pager: any = {};

    // paged items
    pagedItems: any[];
    pageSize: number;
    campaignId: any;
    adGroupId: any;
    errorMsg: any;
    showErrorMsg: boolean;
    hideElement: boolean;
    previousAdGroupsList: any = [];
    previousCampaignsList: any = [];
    clickAdGroupsFromSideNav: boolean;
    previousAdGroupName: any;
    previousCampaignName: any;
    clickFromDashboard: boolean;
    clickfromDashboardCounter: boolean;
    selectedCampaignName: any;
    selectedAdGroupName: any;
    totalCampaignCount: any;
    toalAdGroupCount: any;
    totalAdCount: any;
    totalActiveCampaignsCount: any;
    totalInActiveCampaignsCount: any;
    totalActiveAdgroupsCount: any;
    totalInActiveAdgroupsCount: any;
    totalActiveAdsCount: any;
    totalInActiveAdsCount: any;
    constructor(private http: HttpClient) { }
    getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        let startPage: number, endPage: number;
        // if (totalPages <= 10) {
        //     // less than 10 total pages so show all
        //     startPage = 1;
        //     endPage = totalPages;
        // } else {
        //     // more than 10 total pages so calculate start and end pages
        //     if (currentPage <= 6) {
        //         startPage = 1;
        //         endPage = 10;
        //     } else if (currentPage + 4 >= totalPages) {
        //         startPage = totalPages - 9;
        //         endPage = totalPages;
        //     } else {
        //         startPage = currentPage - 5;
        //         endPage = currentPage + 4;
        //     }
        // }

        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (currentPage + 1 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage + 2;
            }
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        // let pages = _.range(startPage, endPage + 1);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex
        };
    }

    setPage(page: number) {
        // for(var i=0;i<this.dataService.listData.length;i++){
        //   this.isChecked[i]=false;
        //   this.allSelectCheckBox=false;
        // }
        this.pager = this.getPager(this.allItems.length, page, this.pageSize);
        // if (page < 1 || page > this.pager.totalPages) {
        //     return;
        // }

        // get pager object from service


        // get current page of items
        this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
        this.getTotal();
    }
    getTotal() {
        this.totalAmount = 0;

        this.totalImpressions = 0;
        this.totalClicks = 0;
        this.totalEcpm = 0;
        this.totalcpc = 0;
        this.totalGoal = 0;
        this.totalBid = 0;
        for (var i = 0; i < this.pagedItems.length; i++) {
            this.totalAmount += parseFloat(this.pagedItems[i].spend);
            //parseFloat(this.totalAmount);
            this.totalAmount = parseFloat(this.totalAmount);
        }

        for (var i = 0; i < this.pagedItems.length; i++) {
            this.totalImpressions += this.pagedItems[i].impressions;
            parseInt(this.totalImpressions);
        }
        for (var i = 0; i < this.pagedItems.length; i++) {
            this.totalClicks += this.pagedItems[i].clicks;
            parseInt(this.totalClicks);
        }
        for (var i = 0; i < this.pagedItems.length; i++) {
            this.totalEcpm += this.pagedItems[i].ecpm;
            // parseInt(this.totalEcpm);
            this.totalEcpm = parseInt(this.totalEcpm);
        }
        for (var i = 0; i < this.pagedItems.length; i++) {
            this.totalcpc += parseFloat(this.pagedItems[i].cpc);
            this.totalcpc = parseFloat(this.totalcpc);
        }
        for (var i = 0; i < this.pagedItems.length; i++) {
            this.totalGoal += parseFloat(this.pagedItems[i].goal_value);
            this.totalGoal = parseFloat(this.totalGoal);
        }
        for (var i = 0; i < this.pagedItems.length; i++) {
            this.totalBid += parseFloat(this.pagedItems[i].bid_value);
            this.totalBid = parseFloat(this.totalBid);
        }
        this.totalEcpm = this.totalEcpm;
        this.totalAmount = this.totalAmount;
        // console.log("this.totalecpm value:" + this.totalEcpm);
    }
    renameCompaignsData() { // function to rename on button click 
        // for(var i = 0; i < this.compaignGraphList.length; i++){
        //     this.compaignGraphList[i]['value'] = this.compaignGraphList[i]['campaign_spend'];
        //     delete this.compaignGraphList[i].campaign_spend;
        // }
        // console.log("this.compaignGraphList.value:"+this.compaignGraphList)
        // for(var i = 0; i < this.compaignGraphList.length; i++){
        //     this.compaignGraphList[i]['name'] = this.compaignGraphList[i]['campaign_name'];
        //     delete this.compaignGraphList[i].campaign_name;
        // }
        // console.log("this.compaignGraphList.value:"+this.compaignGraphList)

        //   console.log(this.compaignGraphList); 
        this.compaignGraphList = this.compaignGraphList.map(function (obj) {
            obj['value'] = obj['campaign_spend']; // Assign new key 
            //delete obj['campaign_spend']; // Delete old key 
            return obj;
        });
        this.compaignGraphList = this.compaignGraphList.map(function (obj) {
            obj['name'] = obj['campaign_name']; // Assign new key 
            //delete obj['campaign_name']; // Delete old key 
            return obj;
        });
        // console.log(this.compaignGraphList);
    }
    renameAdGroupsData() { // function to rename on button click 
        this.adGroupGraphList = this.adGroupGraphList.map(function (obj) {
            obj['value'] = obj['ad_group_spend']; // Assign new key 
            //delete obj['ad_group_spend']; // Delete old key 
            return obj;
        });
        this.adGroupGraphList = this.adGroupGraphList.map(function (obj) {
            obj['name'] = obj['ad_group_name']; // Assign new key 
            //delete obj['ad_group_name']; // Delete old key 
            return obj;
        });
        // console.log(this.adGroupGraphList);
    }
    renameAdsData() { // function to rename on button click 
        this.adsGraphList = this.adsGraphList.map(function (obj) {
            obj['value'] = obj['ad_spend']; // Assign new key 
            //delete obj['ad_spend']; // Delete old key 
            return obj;
        });
        this.adsGraphList = this.adsGraphList.map(function (obj) {
            obj['name'] = obj['ad_name']; // Assign new key 
            //delete obj['ad_name']; // Delete old key 
            return obj;
        });
        // console.log(this.adsGraphList);
    }
    spendConversion(data) {
        for (var i = 0; i < data.length; i++) {
            data[i].spend = data[i].spend / 1000000;
            data[i].spend = data[i].spend.toFixed(2);
        }
        return data;
        // console.log("data from service spend:" + data);
    }
    top5spendConversion(data) {
        for (var i = 0; i < data.length; i++) {
            if (data[i].campaign_spend) {
                data[i].campaign_spend = data[i].campaign_spend / 1000000;
                data[i].campaign_spend = data[i].campaign_spend.toFixed(2);
            }
            if (data[i].ad_group_spend) {
                data[i].ad_group_spend = data[i].ad_group_spend / 1000000;
                data[i].ad_group_spend = data[i].ad_group_spend.toFixed(2);
            }
            if (data[i].ad_spend) {
                data[i].ad_spend = data[i].ad_spend / 1000000;
                data[i].ad_spend = data[i].ad_spend.toFixed(2);
            }
            if (data[i].campaign_ecpm) {
                data[i].campaign_ecpm = data[i].campaign_ecpm / 1000000;
                data[i].campaign_ecpm = data[i].campaign_ecpm.toFixed(2);
            }
            if (data[i].ad_group_ecpm) {
                data[i].ad_group_ecpm = data[i].ad_group_ecpm / 1000000;
                data[i].ad_group_ecpm = data[i].ad_group_ecpm.toFixed(2);
            }
            if (data[i].ad_ecpm) {
                data[i].ad_ecpm = data[i].ad_ecpm / 1000000;
                data[i].ad_ecpm = data[i].ad_ecpm.toFixed(2);
            }
        }
        return data;
    }
    secondaryDataTotalSpend: any = 0;
    secondaryDataTotalClicks: any = 0;
    secondaryDataTotalImpressions: any = 0;
    secondaryDataTotalEcpm: any = 0;
    getTotalSecondaryData(data) {
        this.secondaryDataTotalSpend = 0;
        this.secondaryDataTotalClicks = 0;
        this.secondaryDataTotalImpressions = 0;
        this.secondaryDataTotalEcpm = 0;
        for (var i = 0; i < data.length; i++) {
            if (data[i].date != undefined) {
                for (var j = 0; j < data[i].date.length; j++) {
                    this.secondaryDataTotalSpend += parseFloat(data[i].date[j].spend);
                    this.secondaryDataTotalSpend = parseFloat(this.secondaryDataTotalSpend).toFixed(2);
                    this.secondaryDataTotalClicks += parseFloat(data[i].date[j].clicks);
                    this.secondaryDataTotalClicks = parseFloat(this.secondaryDataTotalClicks).toFixed(2);
                    this.secondaryDataTotalImpressions += parseFloat(data[i].date[j].impressions);
                    this.secondaryDataTotalImpressions = parseFloat(this.secondaryDataTotalImpressions).toFixed(2);
                    this.secondaryDataTotalEcpm += parseFloat(data[i].date[j].ecpm);
                    this.secondaryDataTotalEcpm = parseFloat(this.secondaryDataTotalEcpm).toFixed(2);
                }
            }
            if (data[i].country != undefined) {
                for (var j = 0; j < data[i].country.length; j++) {
                    this.secondaryDataTotalSpend += parseFloat(data[i].country[j].spend);
                    this.secondaryDataTotalSpend = parseFloat(this.secondaryDataTotalSpend).toFixed(2);
                    this.secondaryDataTotalClicks += parseFloat(data[i].country[j].clicks);
                    this.secondaryDataTotalClicks = parseFloat(this.secondaryDataTotalClicks).toFixed(2);
                    this.secondaryDataTotalImpressions += parseFloat(data[i].country[j].impressions);
                    this.secondaryDataTotalImpressions = parseFloat(this.secondaryDataTotalImpressions).toFixed(2);
                    this.secondaryDataTotalEcpm += parseFloat(data[i].country[j].ecpm);
                    this.secondaryDataTotalEcpm = parseFloat(this.secondaryDataTotalEcpm).toFixed(2);
                }
            }
            if (data[i].metro != undefined) {
                for (var j = 0; j < data[i].metro.length; j++) {
                    this.secondaryDataTotalSpend += parseFloat(data[i].metro[j].spend);
                    this.secondaryDataTotalSpend = parseFloat(this.secondaryDataTotalSpend).toFixed(2);
                    this.secondaryDataTotalClicks += parseFloat(data[i].metro[j].clicks);
                    this.secondaryDataTotalClicks = parseFloat(this.secondaryDataTotalClicks).toFixed(2);
                    this.secondaryDataTotalImpressions += parseFloat(data[i].metro[j].impressions);
                    this.secondaryDataTotalImpressions = parseFloat(this.secondaryDataTotalImpressions).toFixed(2);
                    this.secondaryDataTotalEcpm += parseFloat(data[i].metro[j].ecpm);
                    this.secondaryDataTotalEcpm = parseFloat(this.secondaryDataTotalEcpm).toFixed(2);
                }
            }
            if (data[i].interest != undefined) {
                for (var j = 0; j < data[i].interest.length; j++) {
                    this.secondaryDataTotalSpend += parseFloat(data[i].interest[j].spend);
                    this.secondaryDataTotalSpend = parseFloat(this.secondaryDataTotalSpend).toFixed(2);
                    this.secondaryDataTotalClicks += parseFloat(data[i].interest[j].clicks);
                    this.secondaryDataTotalClicks = parseFloat(this.secondaryDataTotalClicks).toFixed(2);
                    this.secondaryDataTotalImpressions += parseFloat(data[i].interest[j].impressions);
                    this.secondaryDataTotalImpressions = parseFloat(this.secondaryDataTotalImpressions).toFixed(2);
                    this.secondaryDataTotalEcpm += parseFloat(data[i].interest[j].ecpm);
                    this.secondaryDataTotalEcpm = parseFloat(this.secondaryDataTotalEcpm).toFixed(2);
                }
            }
            if (data[i].community != undefined) {
                for (var j = 0; j < data[i].community.length; j++) {
                    this.secondaryDataTotalSpend += parseFloat(data[i].community[j].spend);
                    this.secondaryDataTotalSpend = parseFloat(this.secondaryDataTotalSpend).toFixed(2);
                    this.secondaryDataTotalClicks += parseFloat(data[i].community[j].clicks);
                    this.secondaryDataTotalClicks = parseFloat(this.secondaryDataTotalClicks).toFixed(2);
                    this.secondaryDataTotalImpressions += parseFloat(data[i].community[j].impressions);
                    this.secondaryDataTotalImpressions = parseFloat(this.secondaryDataTotalImpressions).toFixed(2);
                    this.secondaryDataTotalEcpm += parseFloat(data[i].community[j].ecpm);
                    this.secondaryDataTotalEcpm = parseFloat(this.secondaryDataTotalEcpm).toFixed(2);
                }
            }

            data[i]['spend'] = this.secondaryDataTotalSpend;
            data[i]['clicks'] = this.secondaryDataTotalClicks;
            data[i]['impressions'] = this.secondaryDataTotalImpressions;
            data[i]['ecpm'] = this.secondaryDataTotalEcpm;
        }
        return data;
    }
    convertSecondaryDataSpend(data) {
        for (var i = 0; i < data.length; i++) {
            if (data[i].date != undefined) {
                for (var j = 0; j < data[i].date.length; j++) {
                    if (data[i].date[j].spend) {
                        data[i].date[j].spend = data[i].date[j].spend / 1000000;
                        data[i].date[j].spend = data[i].date[j].spend.toFixed(2);
                    }
                }
            }
            if (data[i].country != undefined) {
                for (var j = 0; j < data[i].country.length; j++) {
                    if (data[i].country[j].spend) {
                        data[i].country[j].spend = data[i].country[j].spend / 1000000;
                        data[i].country[j].spend = data[i].country[j].spend.toFixed(2);
                    }
                }
            }
            if (data[i].metro != undefined) {
                for (var j = 0; j < data[i].metro.length; j++) {
                    if (data[i].metro[j].spend) {
                        data[i].metro[j].spend = data[i].metro[j].spend / 1000000;
                        data[i].metro[j].spend = data[i].metro[j].spend.toFixed(2);
                    }
                }
            }
            if (data[i].interest != undefined) {
                for (var j = 0; j < data[i].interest.length; j++) {
                    if (data[i].interest[j].spend) {
                        data[i].interest[j].spend = data[i].interest[j].spend / 1000000;
                        data[i].interest[j].spend = data[i].interest[j].spend.toFixed(2);
                    }
                }
            }
            if (data[i].community != undefined) {
                for (var j = 0; j < data[i].community.length; j++) {
                    if (data[i].community[j].spend) {
                        data[i].community[j].spend = data[i].community[j].spend / 1000000;
                        data[i].community[j].spend = data[i].community[j].spend.toFixed(2);
                    }
                }
            }
            

        }
        return data;
    }
    // addDollartoSpend(){
    //     for(var i=0;i<this.allItems.length;i++){
    //         if(this.allItems[i]=="spend"){

    //         }
    //     }
    // }

    getTopCampaignsData(obj) {
        return this.http.post(this.api + 'top_spend_campaigns', obj);
    }
    getTopAdGroupsData(obj) {
        return this.http.post(this.api + 'top_spend_ad_groups', obj);
    }
    getTopAdsData(obj) {
        return this.http.post(this.api + 'top_spend_ads', obj);
    }
    getCampaignsList(obj) {
        return this.http.post(this.api + 'campaigns_list', obj);
    }
    getAdGroupsList(obj) {
        return this.http.post(this.api + 'ad_groups_list', obj);
    }
    getAdsList(obj) {
        return this.http.post(this.api + 'ads_list', obj);
    }
    getAdgroupsByCampaignId(obj) {
        return this.http.post(this.api + 'ad_groups_by_campaign', obj);
    }
    getAdsByAdGroupId(obj) {
        return this.http.post(this.api + 'ads_by_ad_groups', obj);
    }
    getCampaignsSecondaryData(obj) {
        return this.http.post(this.api + 'campaigns_list', obj);
    }
    getAdGroupsSecondaryData(obj) {
        return this.http.post(this.api + 'ad_groups_list', obj);
    }
    getAdsSecondaryData(obj) {
        return this.http.post(this.api + 'ads_list', obj);
    }
    getAllFilterList(obj) {
        return this.http.post(this.api + 'filters_list', obj);
    }
    getfilterCampaignById(obj) {
        return this.http.post(this.api + 'filtered_data', obj);
    }
    getTotalCounts(obj) {
        return this.http.post(this.api + 'total_advertisement_count', obj);
    }
    getCompaignPersona(obj) {
        return this.http.post(this.api + 'persona_calc_of_campaign', obj);
    }
    getAddGroupsPersona(obj) {
        return this.http.post(this.api + 'persona_calc_of_ad_groups', obj)
    }
    getAddCaclAdsPersona(obj) {
        return this.http.post(this.api + 'persona_calc_of_ads', obj)
    }
}
