import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public serverURL = environment.apiBaseUrl;
  compaignsList = [];
  adGroupsList = [];
  adsList = [];
  top5CompaignsList = [];
  top5AdGroupsList = [];
  top5AdsList = [];
  constructor(public http: HttpClient, private location: Location) { }
  getUserRole(email: string) {
    return this.http.get(this.serverURL + 'access?email=' + email);
  }
  // compaignsList=[
  //   {
  //     name:'Regalix compaign',
  //     date:'08-02-2019',
  //     impressions: 2,
  //     clicks:20,
  //     amount:500,
  //     ecpm:'ecpm',
  //     status:'open',
  //     region:'bangalore',
  //     billing:'yes'
  //   },
  //   {
  //     name:'Regalix compaign1',
  //     date:'08-04-2019',
  //     impressions:22,
  //     clicks:40,
  //     amount:200,
  //     ecpm:'ecpm2',
  //     status:'open',
  //     region:'bangalore',
  //     billing:'yes'
  //   },
  //   {
  //     name:'Regalix compaign2',
  //     date:'18-02-2019',
  //     impressions:7,
  //     clicks:10,
  //     amount:300,
  //     ecpm:'ecpm4',
  //     status:'open',
  //     region:'bangalore',
  //     billing:'no'
  //   },
  //   {
  //     name:'Regalix compaign3',
  //     date:'28-03-2019',
  //     impressions:22,
  //     clicks:40,
  //     amount:900,
  //     ecpm:'ecpm1',
  //     status:'closed',
  //     region:'bangalore',
  //     billing:'yes'
  //   },
  //   {
  //     name:'Regalix compaign4',
  //     date:'08-04-2019',
  //     impressions:6,
  //     clicks:30,
  //     amount:300,
  //     ecpm:'ecpm 5',
  //     status:'open',
  //     region:'bangalore',
  //     billing:'No'
  //   },
  //   {
  //     name:'Regalix compaign5',
  //     date:'18-12-2018',
  //     impressions:8,
  //     clicks:90,
  //     amount:600,
  //     ecpm:'ecpm7',
  //     status:'closed',
  //     region:'bangalore',
  //     billing:'yes'
  //   }
  // ]
  // adGroupsList=[
  //   {
  //     name:'Regalix ad group',
  //     date:'08-02-2019',
  //     impressions:2,
  //     clicks:20,
  //     amount:500,
  //     ecpm:'ecpm',
  //     status:'open',
  //     region:'bangalore',
  //     billing:'no'
  //   },
  //   {
  //     name:'Regalix ad group1',
  //     date:'08-04-2019',
  //     impressions:22,
  //     clicks:40,
  //     amount:200,
  //     ecpm:'ecpm2',
  //     status:'closed',
  //     region:'bangalore',
  //     billing:'yes'
  //   },
  //   {
  //     name:'Regalix ad group2',
  //     date:'18-02-2019',
  //     impressions:7,
  //     clicks:10,
  //     amount:300,
  //     ecpm:'ecpm4',
  //     status:'open',
  //     region:'bangalore',
  //     billing:'yes'
  //   },
  //   {
  //     name:'Regalix ad group3',
  //     date:'28-03-2019',
  //     impressions:22,
  //     clicks:40,
  //     amount:900,
  //     ecpm:'ecpm1',
  //     status:'open',
  //     region:'bangalore',
  //     billing:'no'
  //   },
  //   {
  //     name:'Regalix ad group4',
  //     date:'08-04-2019',
  //     impressions:6,
  //     clicks:30,
  //     amount:300,
  //     ecpm:'ecpm 5',
  //     status:'closed',
  //     region:'bangalore',
  //     billing:'yes'
  //   },
  //   {
  //     name:'Regalix ad group5',
  //     date:'18-12-2018',
  //     impressions:8,
  //     clicks:90,
  //     amount:600,
  //     ecpm:'ecpm7',
  //     status:'open',
  //     region:'bangalore',
  //     billing:'no'
  //   }
  // ]
  // adsList=[
  //   {
  //     name:'Regalix ad',
  //     date:'08-02-2019',
  //     impressions:2,
  //     clicks:20,
  //     amount:500,
  //     ecpm:'ecpm',
  //     status:'open',
  //     region:'bangalore',
  //     billing:'yes'
  //   },
  //   {
  //     name:'Regalix ad1',
  //     date:'08-04-2019',
  //     impressions:22,
  //     clicks:40,
  //     amount:200,
  //     ecpm:'ecpm2',
  //     status:'open',
  //     region:'bangalore',
  //     billing:'no'
  //   },
  //   {
  //     name:'Regalix ad2',
  //     date:'18-02-2019',
  //     impressions:8,
  //     clicks:10,
  //     amount:300,
  //     ecpm:'ecpm4',
  //     status:'open',
  //     region:'bangalore',
  //     billing:'yes'
  //   },
  //   {
  //     name:'Regalix ad3',
  //     date:'28-03-2019',
  //     impressions:22,
  //     clicks:40,
  //     amount:900,
  //     ecpm:'ecpm1',
  //     status:'closed',
  //     region:'bangalore',
  //     billing:'yes'
  //   },
  //   {
  //     name:'Regalix ad4',
  //     date:'08-04-2019',
  //     impressions:6,
  //     clicks:30,
  //     amount:300,
  //     ecpm:'ecpm 5',
  //     status:'open',
  //     region:'bangalore',
  //     billing:'no'
  //   },
  //   {
  //     name:'Regalix ad5',
  //     date:'18-12-2018',
  //     impressions:8,
  //     clicks:90,
  //     amount:600,
  //     ecpm:'ecpm7',
  //     status:'closede',
  //     region:'bangalore',
  //     billing:'yes'
  //   }
  // ]

  getCompaignsData() {
    return this.compaignsList;
  }
  getAdGroupsData() {
    return this.adGroupsList;
  }
  getAdsData() {
    return this.adsList;
  }
  downloadFile(data, filename = 'data') {
    let result = [];
    // data = data.filter(function (el) {
    //   return el != null;
    // });
    let dataKeys = []
    for (let i = 0; i <= data.length; i++) {

      let objKeys = data[0];
      let objectKeys = Object.keys(objKeys);
      for (let j = 0; j <= objectKeys.length; j++) {
        // let objName=Object.keys(objectKeys[j]);
        // data[i][<any>objName];
        if (data[i][objectKeys[j]] == null) {
          delete data[i][objectKeys[j]];
        }

      }
      break;
    }
    // console.log("datat object from api"+data);
    let obj = data[0];


    result.push(Object.keys(obj))

    result = result[0];
    var endsWith = "_id"
    var regx = new RegExp(endsWith + "$");
    dataKeys = result.filter(function (item) { return !regx.test(item); })
    //alert(result);
    // console.log("null remove results"+dataKeys);

    // for(var i=0;i<dataKeys.length;i++){
    //   dataKeys[i]=dataKeys[i].replace("_"," ");
    // }

    // console.log(dataKeys);
    let csvData = this.ConvertToCSV(data, dataKeys);
    // console.log(csvData);

    // console.log(c);


    // b=b.toUpperCase();
    // b=b.split('_').join(' ');
    // console.log("b value" +b);
    // csvData = csvData.replace(/[\w\W]+?\n+?/, ""+b);

    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);

    str += row + '\r\n';
    //var b = csvData.substr(0, csvData.indexOf("\n"));
    //var csvDataLength=csvData.split(/\r\n|\r|\n/);
    var targetValue: any;
    var targetIndex: number;
    var c = [];
    c = str.split(",");
    for (var j = 0; j < c.length; j++) {
      if (c[j].toString().trim() == "target") {
        targetIndex = j;
        targetValue = true;
      }
    }
    for (let i = 0; i < array.length; i++) {
      let line = (i + 1) + '';
      for (let index in headerList) {
        let head = headerList[index];

        line += ',' + array[i][head];

      }
      str += line + '\r\n';

    }
    var csvDataLength = str.split(/\r\n|\r|\n/);
    for (var i = 0; i < csvDataLength.length; i++) {

      // console.log(csvDataLength[i]);
      var targetData: any;
      var splittingData: any
      splittingData = csvDataLength[i].split(",");
      for (var k = 0; k < splittingData.length; k++) {
        if (targetIndex == k) {
          targetData = splittingData[k];
        }
      }
    }

    return str;
  }
  goBack() {
    this.location.back();
  }

}
