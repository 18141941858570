import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.scss']
})
export class ErrorsComponent implements OnInit {

  public index: number;
  constructor(private route: ActivatedRoute,public router:Router,public dataService:DataService,public apiService:ApiService) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.index = parseInt(params.get('id'), 0);
    });

  }
  refresh(){
    this.dataService.clickCompaigns=false;
      this.dataService.clickAdGroups=false;
      this.dataService.clickAds=false;
  this.router.navigate(['redditAds/dashboard']);
  
  }
}
