import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAuthService } from '../../services/google-auth.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { environment } from './../../../../environments/environment'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  googleLoginClientId = environment.googleLoginClientId;
  constructor(private gService: GoogleAuthService,
    private route: Router,
    private apiService:ApiService,
    private flashMessage: FlashMessagesService,
    private ngZone: NgZone) { }

  ngOnInit() {
    window['authComponent'] = { component: this, zone: this.ngZone, onSignIn: (data) => this.onSignIn(data) };
    if (this.gService.isLoggedIn()) {
      this.route.navigate(['redditAds']);
      
      //return true;
    }
  }
  
  onSignIn(data) {  
    this.gService.googleAuth({ idToken: data.credential }).subscribe((gResult) => {
      if (gResult['status'] === true) {
          this.apiService.getUserRole(gResult['data']['email'])
          .subscribe(res => {
              if (res['status'] && res['data']['status'] == 1) {
                localStorage.setItem('user', JSON.stringify(gResult['data']));
                this.route.navigate(['landing']);
              } else if(res['status'] && res['data']['status'] == 0) {
                this.flashMessage.show("Your RMS account is in-active",
              { cssClass: 'alert-danger', position: top, timeout: 2000 });
              this.route.navigate(['login']);
              } else {
              this.flashMessage.show("You don't have permission to access this Application",
              { cssClass: 'alert-danger', position: top, timeout: 2000 });
              this.route.navigate(['login']);
              }
          },
          error => {
          this.route.navigate(['error', error.status]);
          });
      }
    },
    error => {
      this.route.navigate(['error', error.status]);
    });
  }

}
