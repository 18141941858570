import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {

  public serverUrl = environment.apiBaseUrl;
  constructor( public http: HttpClient) {}

  //  signInWithGoogle() {
  //   this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  // }

  signOut() {
    localStorage.clear();
  }
  googleAuth(token) {
    return this.http.post(this.serverUrl + 'gAuth', JSON.stringify(token));
  }
  getAccessToken() {
    return JSON.parse(localStorage.getItem('user'));
  }

  isLoggedIn() {
    return !!localStorage.getItem('user');
  }
}
