import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from './material.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormsModule } from '@angular/forms';
import { OrderByPipe } from './pipes/order-by.pipe';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ErrorsComponent } from './components/errors/errors.component';
import { ClickOutsideModule } from 'ng-click-outside';
@NgModule({
  declarations: [
    OrderByPipe,
    PageNotFoundComponent,
    ErrorsComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxDaterangepickerMd.forRoot(),
    FormsModule,
    NgxChartsModule,
    ClickOutsideModule
  ],
  exports:[
    MaterialModule,
    NgxDaterangepickerMd,
    FormsModule,
    OrderByPipe,
    NgxChartsModule,
    PageNotFoundComponent,
    ErrorsComponent,
    ClickOutsideModule
  ],
  providers: []
})
export class SharedModule { }
