import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

  constructor(public router:Router,public dataService:DataService,public apiService:ApiService) { }

  ngOnInit() {
  }
  refresh(){
    
  this.router.navigate(['landing']);
    
  }
}
