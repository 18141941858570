import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';
import { ApiService } from 'src/app/shared/services/api.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {


  constructor(private route:Router,
              private dataService: DataService,
              private apiService:ApiService) { }

  ngOnInit() {
  }
  gotoDashboard(){
    this.route.navigate(['redditAds']);
    let s1,s2,e1,e2;
    var startDate = new Date();
    let endDate = new Date();
    endDate.setDate(startDate.getDate() -7)
    // console.log(endDate)
    if (startDate.getDate() < 10) {
      s1 = '0'+ parseInt(<any>startDate.getDate());
    } else {
      s1 = startDate.getDate()
    }
    if (startDate.getMonth() < 9) {
      s2='0'+ parseInt(<any>startDate.getMonth()+1);
      
    
      // console.log(s2);
    } else {
      s2 = startDate.getMonth()+1
    }
    if (endDate.getDate() < 10) {
      e1 = '0'+ parseInt(<any>endDate.getDate());
    } else {
      e1 = endDate.getDate()
    }
    if (endDate.getMonth() < 9) {
      e2 = '0'+ parseInt(<any>endDate.getMonth()+1);
    } else {
      e2 = endDate.getMonth()+1
    }
    this.dataService.startDate=startDate.getFullYear() + '-' + (s2) + '-' + s1;
    this.dataService.endDate=endDate.getFullYear() + '-' + (e2) + '-' + e1;
    localStorage.setItem("accountID",this.dataService.accountID)
    this.getTotalCount();
    const obj = {
      account_id : this.dataService.accountID,
      end_date: this.dataService.startDate,
      start_date: this.dataService.endDate
    }
    // console.log("Landing page"+obj);
    //this.dataService.showLoader=true;
    this.dataService.clickCompaigns=false;
    this.dataService.clickAdGroups=false;
    this.dataService.clickAds=false;
    const filterObj={
      account_id : this.dataService.accountID
    }
    this.dataService.getAllFilterList(filterObj).subscribe(
        (res) => {
          // console.log(res);
          // console.log("response"+res["data"])
          
          if(res["data"]){
            if(res['data'].length!=0){
              let filterList=res["data"][0];
            // console.log("filterList from landing page"+filterList);
            this.dataService.filterCampaignsList=filterList.campaigns;
            this.dataService.filterAdGroupsList=[];
            this.dataService.filterAdsList=[];
            for(var i=0;i<this.dataService.filterCampaignsList.length;i++){
              if(this.dataService.filterCampaignsList[i].ad_groups.length!=0){
                for(var j=0;j<this.dataService.filterCampaignsList[i].ad_groups.length;j++){
                  this.dataService.filterAdGroupsList.push(this.dataService.filterCampaignsList[i].ad_groups[j])
                }
              }
              
            }
            for(var k=0;k<this.dataService.filterAdGroupsList.length;k++){
              if(this.dataService.filterAdGroupsList[k].ads.length!=0){
                for(var l=0;l<this.dataService.filterAdGroupsList[k].ads.length;l++){
                  this.dataService.filterAdsList.push(this.dataService.filterAdGroupsList[k].ads[l])
                }
              }
             // this.dataService.filterAdsList.push(this.dataService.filterAdGroupsList[i].ads)
            }
            for(var i=0;i<this.dataService.filterCampaignsList.length;i++){
              this.dataService.filterCampaignsList[i]['isChecked']=false;
              
              
            }
            for(var i=0;i<this.dataService.filterAdGroupsList.length;i++){
              this.dataService.filterAdGroupsList[i]['isChecked']=false;
              
            }
            for(var i=0;i<this.dataService.filterAdsList.length;i++){
              this.dataService.filterAdsList[i]['isChecked']=false;
              
            }
            this.dataService.originalFilterCampaignsList=JSON.parse(JSON.stringify(this.dataService.filterCampaignsList));
            this.dataService.originalFilterAdGroupsList=JSON.parse(JSON.stringify(this.dataService.filterAdGroupsList));
            this.dataService.originalFilterAdsList=JSON.parse(JSON.stringify(this.dataService.filterAdsList));
            // console.log("this.dataService.filterAdGroupsList from landing:" +this.dataService.filterAdGroupsList);
            // console.log("this.dataService.filterAdsList from landing:" +this.dataService.filterAdsList);
            
            }
            if(res['data'].length==0){
              
            }
            
          }else{
            // console.log("errorMessage");
            
          }
          
        },
        (error) => {
          // console.log("error block msg from landing page"+error)
          //this.dataService.showLoader=false;
          this.dataService.hideElement=true;
          this.route.navigate(['landing']);
        }
      )


    // this.dataService.getData(obj).subscribe(
    //   (res) => {
    //     console.log(res);
    //     console.log("response"+res["data"])
        
    //     if(res["data"]){
    //       if(res['data'].length!=0){
    //         let top5List=res["data"];
    //       this.apiService.top5CompaignsList=top5List[0].campaigns;
    //       this.apiService.top5AdGroupsList=top5List[1].ad_groups;
    //       this.apiService.top5AdsList=top5List[2].ads;
    //       //this.route.navigate(['redditAds']);
    //       this.apiService.compaignsList=this.apiService.top5CompaignsList;
    //       this.apiService.adGroupsList=this.apiService.top5AdGroupsList;
    //       this.apiService.adsList=this.apiService.top5AdsList;
    //       this.dataService.compaignGraphList=JSON.parse(JSON.stringify(this.apiService.compaignsList));
    //       this.dataService.adGroupGraphList=JSON.parse(JSON.stringify(this.apiService.adGroupsList));
    //       this.dataService.adsGraphList=JSON.parse(JSON.stringify(this.apiService.adsList));
          
    //         this.dataService.renameCompaignsData();
    //         this.dataService.renameAdGroupsData();
    //         this.dataService.renameAdsData();
    //       this.dataService.showLoader=false;
    //       this.dataService.hideElement=false;
    //       this.dataService.errorMsg='';
    //       this.dataService.showErrorMsg=false;
    //       }
    //       if(res['data'].length==0){
    //         this.dataService.showLoader=false;
    //         this.dataService.hideElement=true;
    //         this.dataService.showErrorMsg=true;
    //         this.dataService.errorMsg="No Data Available. Please check with other Account Number";
    //       }
          
    //     }else{
    //       console.log("errorMessage");
    //       this.route.navigate(['landing']);
    //       this.dataService.showLoader=false;
    //       this.dataService.errorMsg='';
          
    //       this.dataService.showErrorMsg=true;
    //       this.dataService.hideElement=true;
    //       this.dataService.errorMsg=res['error'].message;
    //     }
        
    //   },
    //   (error) => {
    //     console.log("error block msg from landing page"+error)
    //     this.dataService.showLoader=false;
    //     this.dataService.hideElement=true;
    //     this.route.navigate(['landing']);
    //   }
    // )
    
    
    
    // this.route.navigate(['redditAds']);
  }
getTotalCount(){
  const obj = {
    account_id : this.dataService.accountID
  }
  // console.log(obj);
  //this.dataService.showLoader=true;
  this.dataService.getTotalCounts(obj).subscribe(
    (res) => {
      if(res["data"]){
        if(res["data"].length!=0){
          // console.log(res);
          // console.log("response"+res["data"])
          let totalCounts=res["data"];
         this.dataService.totalCampaignCount=totalCounts[0].campaigns_count;
         this.dataService.toalAdGroupCount=totalCounts[1].ad_groups_count;
         this.dataService.totalAdCount=totalCounts[2].ads_count;

         this.dataService.totalActiveCampaignsCount=totalCounts[0].campaign_configure_status.ACTIVE;
         this.dataService.totalInActiveCampaignsCount=totalCounts[0].campaign_configure_status.INACTIVE;
         this.dataService.totalActiveAdgroupsCount=totalCounts[1].ad_group_configure_status.ACTIVE;
         this.dataService.totalInActiveAdgroupsCount=totalCounts[1].ad_group_configure_status.INACTIVE;
         this.dataService.totalActiveAdsCount=totalCounts[2].ad_configure_status.ACTIVE;
         this.dataService.totalInActiveAdsCount=totalCounts[2].ad_configure_status.INACTIVE;
        }
        if(res['data'].length==0){
          // this.responseData=res['data'];
          // this.dataService.showLoader=false;
          // this.dataService.showErrorMsg=true;
          // this.dataService.errorMsg="No Data Available. Please check with other Account Number";
          // this.dataService.hideElement=true;
        }
        //this.dataService.hideElement=false;
      
      }else{
        
        
      }
    },
    (error) => {
      // console.log(error)
      this.route.navigate(['/error', { id: error.status}]);
     // this.route.navigate(['landing']);
    }
  )
}
}
