import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/components/login/login.component';
import { APP_ROUTES } from './shared/routes/app.routes';

import { ContentLayoutComponent } from './common/content-layout/content-layout.component';
import { LandingComponent } from './common/landing/landing.component';
import { ErrorsComponent } from './shared/components/errors/errors.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { AuthGuard } from './auth/guards/auth.guard';


const routes: Routes = [
  {path:'', redirectTo:'login',pathMatch:"full"},
  {path:"login", component:LoginComponent},
  {path:'landing', component:LandingComponent, canActivate: [AuthGuard] }, 
  {path:'redditAds', component:ContentLayoutComponent,loadChildren: 'src/app/home/home.module#HomeModule', canActivate: [AuthGuard]},
  {
    path: 'error/:id',
    component: ErrorsComponent,

  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
